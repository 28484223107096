import React from 'react'

import CampaignButton from 'src/components/campaign/button'
import AdmissionButton from 'src/components/admission/button'

import styles from './index.module.scss'


const Bottom = () => {

    return (
        <div className={"fixed " + styles.bottom}>
            <div className={styles.wrapper}>
                <div className={styles.flex}>
                    <CampaignButton className={styles.campaign} />
                    <AdmissionButton className={styles.admission} />
                </div>
            </div>
        </div>
    )
}

export default Bottom
