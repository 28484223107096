import React from 'react'

import Img from 'gatsby-image/withIEPolyfill'

import { useStaticQuery, graphql } from 'gatsby'


const Image = ({ src, className, alt, minWidth=667 }) => {

    const data = useStaticQuery(graphql`
        query {
            images: allFile(
                filter: {
                    relativePath: { regex: "/^page/" }
                }
            ) {
                nodes {
                    relativePath
                    childImageSharp {
                        fluid(maxWidth: 1920, quality: 95) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                }
            }
        }
    `)

    if (src && src.match(/(.+)\.(jpg|png)/)) {
        const srcPC = minWidth > 0 ? `${RegExp.$1}@pc.${RegExp.$2}` : `${RegExp.$1}.${RegExp.$2}`
        const srcSP = `${RegExp.$1}@sp.${RegExp.$2}`

        const imagePC = data.images.nodes.find((node) => {
            return node.relativePath.includes(srcPC)
        });

        const imageSP = data.images.nodes.find((node) => {
            return node.relativePath.includes(srcSP)
        });

        const sources = []

        if (imagePC) {
            sources.push({ ...imagePC.childImageSharp.fluid, media: `(min-width: ${minWidth}px)` })
        }
        if (imageSP) {
            sources.push({ ...imageSP.childImageSharp.fluid })
        }

        if (sources.length > 0) {
            return <Img fluid={sources} className={className} fadeIn={true} alt={alt || ""} />
        } else {
            return null
        }
    } else {
        return null
    }
}

export default Image