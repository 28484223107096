import React, { useEffect, useState } from 'react'

import { useLocation } from '@reach/router'

import Modal from './modal'

const Button = ({ ...props }) => {
	const [open, setOpen] = useState(false)
	const { pathname } = useLocation()

	useEffect(() => {
		setOpen(false)
	}, [pathname])

	return (
		<React.Fragment>
			<button onClick={() => setOpen(true)} {...props}>
				入会はこちら
			</button>

			<Modal isOpen={open} close={() => setOpen(false)} />
		</React.Fragment>
	)
}

export default Button
