import React from 'react'

import Link from 'src/components/link/index'
import Image from 'src/components/image/index'
import Modal from 'src/components/modal/index'

import styles from './modal.module.scss'

const AdmissionModal = ({ isOpen, close }) => {
	const send = (name) => {
		if (`gtag` in window) {
			// window.ga('send', 'event', 'formlink', 'click', name, 1)
			window.gtag(`event`, `click`, {
				event_category: `formlink`,
				event_label: name,
				value: 1,
			})
		}
	}

	return (
		<Modal className={styles.modal} isOpen={isOpen}>
			<div className={styles.wrapper}>
				<button onClick={close} aria-label="close" className={styles.back}></button>
				<div className={styles.content}>
					<ul>
						<li>
							<Link to="https://entry.thefit.jp/join/000101/" onClick={() => send(`toritsudai`)}>
								<div className={styles.placer}>
									<Image src="/top/gym_toritsudai.jpg" minWidth={0} />
								</div>
								<div className={styles.text}>
									<h3>WE ARE THE FIT TORITSUDAI</h3>
									<button>都立大店で入会する</button>
								</div>
							</Link>
						</li>
						<li>
							<Link to="https://entry.thefit.jp/join/000102/" onClick={() => send(`ekoda`)}>
								<div className={styles.placer}>
									<Image src="/top/gym_ekoda.jpg" minWidth={0} />
								</div>
								<div className={styles.text}>
									<h3>WE ARE THE FIT EKODA</h3>
									<button>江古田店で入会する</button>
								</div>
							</Link>
						</li>
					</ul>
				</div>
				<button onClick={close} aria-label="close" className={styles.close}></button>
			</div>
		</Modal>
	)
}

export default AdmissionModal
