import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { useLocation, navigate } from '@reach/router'
import $ from 'jquery'

const Link = ({ to, state, children, afterClick, ...props }) => {
	const internal = /^\/(?!\/)/.test(to)

	const { pathname } = useLocation()

	const handleClick = (e) => {
		e.preventDefault()

		if (to.match(/(.+)#(.*)/)) {
			if (pathname === RegExp.$1) {
				scroll(RegExp.$2)
			} else {
				goto(to)
			}
		} else {
			goto(to)
		}
		if (afterClick) {
			afterClick()
		}
	}

	const scroll = (id) => {
		const el = document.getElementById(id)
		const top = el ? $(el).offset().top : 0
		$('html,body').stop().animate({ scrollTop: top }, 800, 'ioX4')
	}

	const goto = (to) => {
		setTimeout(() => {
			navigate(to, { state })
		}, 0)
	}

	if (internal) {
		return (
			<GatsbyLink to={to} onClick={handleClick} {...props}>
				{children}
			</GatsbyLink>
		)
	} else {
		return (
			<a href={to} target="_blank" rel="noopener noreferrer" {...props}>
				{children}
			</a>
		)
	}
}

export default Link
