import React, { useEffect, useState } from 'react'

import { useLocation } from '@reach/router'

import { get } from 'src/lib/api'

import Modal from './modal'


const Button = ({ ...props }) => {

    const [ open, setOpen ] = useState(false)
    const [ data, setData ] = useState(false)
    const { pathname } = useLocation()

    useEffect(() => {
        if (typeof window !== `undefined`) {
            get(`/app/data/campaign.json`).then((data) => {
                setData(data.campaign)
            })
        } else {
            setData({})
        }
    }, [])

    useEffect(() => {
        setOpen(false)
    }, [pathname])
    

    if (data) {
        return (
            <React.Fragment>
                <button onClick={() => setOpen(true)} {...props}>キャンペーン情報</button>
                <Modal isOpen={open} close={() => setOpen(false)} data={data} />
            </React.Fragment>
        )
    } else {
        return null
    }
}

export default Button