import React from 'react'

import { useLocation } from '@reach/router'

import Link from 'src/components/link/index'

import styles from './nav_pc.module.scss'


const NavPC = () => {

    const { pathname } = useLocation()

    return (
        <div className={styles.navPc}>
            <ul>
                <li><Link to={pathname === '/' ? '/#news' : '/news/'}>NEWS</Link></li>
                <li><Link to="/#about">ABOUT</Link></li>
                <li><Link to="/#gyms">GYMS</Link></li>
                <li><Link to="/#price">PRICE</Link></li>
                <li><Link to="/beginners/">FOR BEGINNER</Link></li>
            </ul>
        </div>
    )
}

export default NavPC
