import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

import { CSSTransition } from 'react-transition-group'
import $ from 'jquery'

import styles from './index.module.scss'
import transitoin from './transition.module.scss'


const Modal = ({ className, isOpen, children }) => {

    useEffect(() => {
        return () => {
            $('.fixed').css({ paddingRight: '' })
            document.body.style.paddingRight = ''
            document.documentElement.style.overflow = ''
        }
    }, [])

    useEffect(() => {
        if (isOpen) {
            const padding = window.innerWidth - document.body.clientWidth
            $('.fixed').css({ paddingRight: padding })
            document.body.style.paddingRight = padding + 'px'
            document.documentElement.style.overflow = 'hidden'
        } else {
            $('.fixed').css({ paddingRight: '' })
            document.body.style.paddingRight = ''
            document.documentElement.style.overflow = ''
        }
    }, [isOpen])

    if (typeof window !== `undefined`) {
        const _className = styles.modal + (className ? ` ${className}` : ``)

        return (
            ReactDOM.createPortal((
                <CSSTransition in={isOpen} timeout={500} unmountOnExit classNames={transitoin}>
                    <div className={_className}>
                        {children}
                    </div>
                </CSSTransition >
            ), document.body)
        )
    } else {
        return null
    }
}


export default Modal