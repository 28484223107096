import React from 'react'

import Modal from 'src/components/modal/index'

import styles from './dialog.module.scss'


const Dialog = ({ isOpen, close, address }) => {

    return (
        <Modal className={styles.modal} isOpen={isOpen}>
            <div className={styles.wrapper}>
                <button onClick={close} aria-label="close" className={styles.back}></button>

                <div className={styles.content}>
                    <a href={`mailto:${address}`} onClick={close}>メールを開く</a>
                    <button onClick={close}>キャンセル</button>
                </div>
            </div>
        </Modal>
    )
}

export default Dialog