import React, { useContext, useEffect, useState } from 'react'

import { useLocation } from '@reach/router'
import { ColsContext } from 'src/hooks/cols/context'
import { HeaderContext } from 'src/hooks/header/context'
import CampaignButton from 'src/components/campaign/button'
import AdmissionButton from 'src/components/admission/button'

import Polaris from 'src/lib/Polaris'
import NavPC from './nav_pc'
import NavSP from './nav_sp'
import Link from 'src/components/link/index'

import styles from './index.module.scss'


const Header = () => {

	const [ open, setOpen ] = useState(false)

	const { pathname } = useLocation()

	const { cols } = useContext(ColsContext)

	const { hasBack } = useContext(HeaderContext)

	const [scrolled, setScrolled] = useState(false)


	useEffect(() => {
		const scrollID = Polaris.util.onScroll((scrollTop) => {
			if (scrollTop > 30) {
				setScrolled(true)
			} else {
				setScrolled(false)
			}
		})
		return () => Polaris.util.offScroll(scrollID)
	}, [])

	useEffect(() => {
		setOpen(false)
	}, [cols])

	useEffect(() => {
		setOpen(false)
	}, [pathname])


	return (
		<React.Fragment>
			<header className={styles.header} data-has-back={hasBack} data-scrolled={scrolled} data-expanded={!open}>

				{cols !== 'line_12' && (
					<NavSP open={open} close={() => setOpen(false)} />
				)}

				<div className={styles.wrapper + ' fixed'}>
					<div className={styles.content}>
						{pathname === '/' ? (
							<h1><Link to="/#" className={styles.logo} afterClick={() => setOpen(false)}>WE ARE THE FIT</Link></h1>
						) : (
							<Link to="/" className={styles.logo}>WE ARE THE FIT</Link>
						)}
						
						{cols === 'line_12' && (
							<NavPC />
						)}

						<div className={styles.right}>
							{cols !== 'line_sp' && (
								<div className={styles.buttons}>
									<div>
										<CampaignButton className={styles.forCampaign} />
										<AdmissionButton className={styles.forAdmission} />
									</div>
								</div>
							)}

							<button className={styles.menuButton} onClick={() => setOpen(!open)} aria-expanded={open} aria-controls="nav-sp" aria-label="MENU">
								<div><span></span></div>
							</button>
						</div>
					</div>
				</div>
			</header>
		</React.Fragment>
	)
}

export default Header
