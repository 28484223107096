// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-agreement-index-js": () => import("./../../../src/pages/agreement/index.js" /* webpackChunkName: "component---src-pages-agreement-index-js" */),
  "component---src-pages-beginners-index-js": () => import("./../../../src/pages/beginners/index.js" /* webpackChunkName: "component---src-pages-beginners-index-js" */),
  "component---src-pages-body-styling-index-js": () => import("./../../../src/pages/body-styling/index.js" /* webpackChunkName: "component---src-pages-body-styling-index-js" */),
  "component---src-pages-company-index-js": () => import("./../../../src/pages/company/index.js" /* webpackChunkName: "component---src-pages-company-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-gyms-ekoda-index-js": () => import("./../../../src/pages/gyms/ekoda/index.js" /* webpackChunkName: "component---src-pages-gyms-ekoda-index-js" */),
  "component---src-pages-gyms-toritsudai-index-js": () => import("./../../../src/pages/gyms/toritsudai/index.js" /* webpackChunkName: "component---src-pages-gyms-toritsudai-index-js" */),
  "component---src-pages-iatf-index-js": () => import("./../../../src/pages/iatf/index.js" /* webpackChunkName: "component---src-pages-iatf-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-law-index-js": () => import("./../../../src/pages/law/index.js" /* webpackChunkName: "component---src-pages-law-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */)
}

