import React, { useEffect } from 'react'

import { useLocation } from '@reach/router'
import $ from 'jquery'

import Link from 'src/components/link/index'

import styles from './nav_sp.module.scss'

const NavSP = ({ open, close }) => {
	const { pathname } = useLocation()

	useEffect(() => {
		const $nav = $(`.${styles.nav}`)

		if (open) {
			const h0 = $nav.height()
			const h1 = $nav.addClass(styles.opened).height()

			$nav.removeClass(styles.opened)
			$nav.stop()
				.css({ height: h0 })
				.animate({ height: h1 }, 600, 'ioX4', () => {
					$nav.css({ height: `` }).addClass(styles.opened)
				})
		} else {
			const h0 = $nav.height()

			$nav.removeClass(styles.opened)
			$nav.stop().css({ height: h0 }).animate({ height: 0 }, 600, 'ioX4')
		}

		if (open) {
			$('body, .fixed').css({ paddingRight: window.innerWidth - document.body.clientWidth })
			document.documentElement.style.overflow = 'hidden'
		} else {
			$('body, .fixed').css({ paddingRight: '' })
			document.documentElement.style.overflow = ''
		}
	}, [open])

	return (
		<div className={styles.nav}>
			<ul>
				<li>
					<Link afterClick={close} to={pathname === '/' ? '/#news' : '/news/'}>
						NEWS
					</Link>
				</li>
				<li>
					<Link afterClick={close} to="/#about">
						ABOUT
					</Link>
				</li>
				<li>
					<Link afterClick={close} to="/#gyms">
						GYMS
					</Link>
				</li>
				<li>
					<Link afterClick={close} to="/#price">
						PRICE
					</Link>
				</li>
				<li>
					<Link afterClick={close} to="/faq/">
						FAQ
					</Link>
				</li>
				<li>
					<Link afterClick={close} to="/beginners/">
						FOR BEGINNER
					</Link>
				</li>
				<li>
					<Link afterClick={close} to="/iatf/">
						I AM THE FIT
					</Link>
				</li>
				<li>
					<Link afterClick={close} to="https://body-s.jp/ekoda/">
						Body Styling
					</Link>
				</li>
			</ul>
		</div>
	)
}

export default NavSP
