import React from 'react'

import Link from 'src/components/link/index'
import Modal from 'src/components/modal/index'

import styles from './modal.module.scss'


const CampaignModal = ({ isOpen, close, data  }) => {

    return (
        <Modal className={styles.modal} isOpen={isOpen}>
            <div className={styles.wrapper}>
                <button onClick={close} aria-label="close" className={styles.back}></button>
                <div className={styles.content}>
                    <Link to={`/news/${data.slug}`}>
                        <img src={data.mainimage} alt="" />
                    </Link>
                    <button onClick={close} aria-label="close" className={styles.pcClose}>&#xe907;</button>
                </div>
                <button onClick={close} aria-label="close" className={styles.spClose}>&#xe908;</button>
            </div>
        </Modal>
    )
}

export default CampaignModal